/* src/styles/englishBook.css */
.book-layout {
  display: flex;
  height: 100vh;
  position: relative;
}

.side-menu {
  position: fixed;
  left: -250px;
  top: 0;
  width: 250px;
  height: 100vh;
  background: #fff;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  transition: left 0.3s ease;
  z-index: 1000;
}

.side-menu.show {
  left: 0;
}

.book-content {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.menu-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 100;
  padding: 8px 16px;
  background: rgba(255,255,255,0.9);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.book-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  position: relative;
  max-width: 100%;
  max-height: 100vh;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.side-menu-content {
  /*padding: 10px;*/
}

/* 修改 englishBook.css */
.side-menu {
  background: #e6f3ff; /* 浅蓝色背景 */
  border-right: 3px solid #b2dfd6;
}

.page-item {
  padding: 10px 15px;
  margin: 8px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  border: 2px dashed #b2dfd6; /* 虚线边框 */
  font-size: 16px;
  color: #666;
  display: flex;
  align-items: center;
}

.page-item::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #b2dfd6;
  border-radius: 50%;
  margin-right: 10px;
}

.page-item:hover {
  transform: translateX(5px);
  background: #f0f8ff;
  border-color: #7fcec3;
}

.page-item.active {
  background: #e6f3ff;
  border-color: #7fcec3;
  border-style: solid;
  color: #333;
}

.book-title {
  font-family: 'Comic Sans MS', cursive;
  color: #4a90e2;
  text-align: center;
  padding: 15px;
  font-size: 20px;
  background: #f0f8ff;
  border-bottom: 2px dashed #b2dfd6;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .menu-toggle {
    top: 50px;
  color: #b2dfd6;
    left: 10px;
  background: #f4f4f4;
  }
}

.play-intro-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background: #b2dfd6;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
}

.play-intro-button:active {
  transform: translateX(-50%) scale(0.95);
}