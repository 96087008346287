/* src/components/BookPage.css */
.book-page {
  position: relative;
  width: 100%;
}

.image-container {
  position: relative;
  width: 100%;
}

.highlight-area {
  position: absolute;
  background-color: rgba(255, 217, 0, 0.2);
  border: 2px solid rgba(243, 180, 11, 0.5);
  border-radius: 12px;
  pointer-events: none;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 186, 0, 0.3);
}

.animated-hand {
  position: absolute;
  width: 50px;
  height: 50px;
  /*background-image: url('../assets/shouzhi.png'); !* 修改这里的路径 *!*/
  /* 或者使用 */
   background-image: url('../../public/shouzhi.png');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 100;
  animation: float 1.5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px) rotate(10deg);
  }
  50% {
    transform: translateY(-15px) rotate(10deg);
  }
  100% {
    transform: translateY(0px) rotate(10deg);
  }
}

/* BookPage.css 添加加载状态样式 */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}